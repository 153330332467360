.litter_parent {
    display: flex;
}

.search_row {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
}

@media (max-width: 720px) {
    .search_row {
        flex-direction: column;
    }

    .litter_parent {
        flex-direction: column;
    }

    .litter_parent .cat_avatar {
        height: auto;
    }
}

.search_row > * {
    padding: 10px;
}