.button {
    outline: none;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    padding: 11px 32px;
    color: #ffffff;
}

.button:hover {
    text-shadow: rgba(255, 255, 255, 0.58) 1px 1px 1px;
}

.button.green {
    border: solid white 1px;
    background: var(--green);
}

.button.green:hover {
    border: solid 1px var(--green);
}

.button.white {
    border: solid white 1px ;
    background: transparent;
}

.button.white:hover {
    box-shadow: 0 0 40px rgba(0, 0, 0, .2) inset;
}

.button.gray {
    color: #525252;
    border: solid rgba(194, 194, 194, 0.8) 1px ;
    background: #eaeaea;
}

.button.gray:hover {
    box-shadow: 0 0 40px rgba(0, 0, 0, .2) inset;
}




