@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap&subset=cyrillic,cyrillic-ext');

body {
    margin: 0 !important;
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    --green: #329b21;
    --red: #ff0000b3;
    --green_transparent: #329b21a3;
}

.content {
    margin: 5% 20%;
}

.color_green {
    color: var(--green);
}

.color_red {
    color: red;
}

.color_blue {
    color: blue;
}

html .bg_green {
    background-color: var(--green);
}

@media (max-width: 720px) {
    .content {
        margin: 5% 10%;
    }
}

.react-datepicker-wrapper{
    display: inherit!important;
}