.auth_form{
    text-align: center;
}

.auth_form input{
    /*margin: auto;*/
}

.error_message{
    color: var(--red);
    margin-bottom: 20px;
    margin-top: -10px;
}
