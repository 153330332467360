.contacts a{
    text-decoration: none;
    color: black;
}

.contacts{
    display: flex;
    flex-wrap: wrap;
}

.avatar{
    margin: 10px;
    padding: 10px;
    border: 3px solid  var(--green_transparent);
    width: 250px;
    min-height: 250px;
}

.avatar img{
    width: inherit;
    height: auto;
}
