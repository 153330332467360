.cat_avatar > img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    display: block;
}

.cat_avatar {
    width: 200px;
    height: 200px;
    vertical-align: middle;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    text-align: center;
}

a>.cat_avatar{
    cursor: pointer;
}

