.green_rectangle {
    background-color:  var(--green_transparent);
    height: calc(100% - 5vh);
    width: 35%;
    margin-left: 3%;
}

.green_rectangle > div {
    padding: 20% 40px;
    color: white;
    font-size: 3vh;
    font-weight: bold;
}

.register_description{
    font-weight: 400;
    font-size: 16px;
    margin: 20px;
}

@media (max-width: 720px) {
    .green_rectangle{
        width: auto;
        margin-left: 0;
    }
}
