.cats_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.cats_list .cat_preview {
    box-shadow: inset 0 0 7px 0 green;
    width: 100%;
}

@media (max-width: 1000px) {
    .cats_list .cat_preview .cat_avatar {
        height: auto;
    }
}

