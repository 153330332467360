@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap&subset=cyrillic,cyrillic-ext);
body {
    margin: 0 !important;
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    --green: #329b21;
    --red: #ff0000b3;
    --green_transparent: #329b21a3;
}

.content {
    margin: 5% 20%;
}

.color_green {
    color: var(--green);
}

.color_red {
    color: red;
}

.color_blue {
    color: blue;
}

html .bg_green {
    background-color: var(--green);
}

@media (max-width: 720px) {
    .content {
        margin: 5% 10%;
    }
}

.react-datepicker-wrapper{
    display: inherit!important;
}
.pagination {
    text-align: center;
}

.pagination > li {
    display: inline-block;
}

.pagination > li > a,
.pagination > li > span {
    cursor: pointer;
    position: relative;
    float: left;
    padding: 6px 12px;
    text-decoration: none;
    border: 1px solid #329b21;
}

.pagination > li.active > a {
    color: white;
    background-color: #329b21;
    border-color: #329b21;
}

/* Style the active class (and buttons on mouse-over) */
.pagination > li > a:hover {
    background-color: #329b21;
    color: white;
}

.break-me > a {
    border: none !important;
}

.break-me > a:hover {
    background: none !important;
    color: inherit !important;
}

@media (max-width: 1000px) {
    .pagination > li {
        display: none;
    }

    .previous, .next, .active {
        display: inline-block !important;
    }

    .pagination > li > a:hover {
        color: black;
        background-color: white;
    }

}

.header {
    width: 100%;
    /*height: 35vw;*/
    /*background-image: url(./assets/title_image.png);*/
    /*background-position: center;*/
    /*background-size: cover;*/
}
.menu{
    height: 5vh;
    display:flex;
    background-color:  var(--green_transparent);
    color: white;
    font-size: 3vh;
}

.menu_title{
    margin: auto 0 auto 1vh;
    font-weight: bold;
    font-family: Montserrat, sans-serif;
}

.menu_items{

    width: 50vw;
    display: flex;
    font-size: 2vh;
    margin: auto;
    justify-content: space-evenly;
}

@media (max-width: 720px) {
    .menu{
        flex-wrap: wrap;
    }
    .menu_items{
        margin-top: 15px;
        justify-content: space-evenly;
        width: 100%;
        flex-wrap: wrap;
    }
}

.menu_link {
    text-decoration: none;
    color: white;
}

.menu_link_current {
    border-bottom: 1px solid white;
}

.link {
    text-decoration: none;
}

.link:hover{
    text-shadow: #ffffff6b 1px 1px 1px;
}

.green_rectangle {
    background-color:  var(--green_transparent);
    height: calc(100% - 5vh);
    width: 35%;
    margin-left: 3%;
}

.green_rectangle > div {
    padding: 20% 40px;
    color: white;
    font-size: 3vh;
    font-weight: bold;
}

.register_description{
    font-weight: 400;
    font-size: 16px;
    margin: 20px;
}

@media (max-width: 720px) {
    .green_rectangle{
        width: auto;
        margin-left: 0;
    }
}

.button {
    outline: none;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    padding: 11px 32px;
    color: #ffffff;
}

.button:hover {
    text-shadow: rgba(255, 255, 255, 0.58) 1px 1px 1px;
}

.button.green {
    border: solid white 1px;
    background: var(--green);
}

.button.green:hover {
    border: solid 1px var(--green);
}

.button.white {
    border: solid white 1px ;
    background: transparent;
}

.button.white:hover {
    box-shadow: 0 0 40px rgba(0, 0, 0, .2) inset;
}

.button.gray {
    color: #525252;
    border: solid rgba(194, 194, 194, 0.8) 1px ;
    background: #eaeaea;
}

.button.gray:hover {
    box-shadow: 0 0 40px rgba(0, 0, 0, .2) inset;
}





.footer {
    background-color: var(--green);
    padding: 0 20%;
    color: white;
}
.footer a{
    color: white;
}

.footer > div {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    border-bottom: 1px solid white;
    flex-wrap: wrap;
}

.footer>:last-child {
    border: none;
}

.title_h2 {
    display: flex;
    margin-bottom: 1em;
}

/*.title_h2 > h2 {*/
h2 {
    margin: 0;
    font-family: Montserrat, sans-serif;
    font-weight: 400;
}

.title_h2_lines {
    margin-right: 40px;
    margin-top: 7px;
    width: 100px;
    border-top: 3px solid  var(--green_transparent);
}

.title_h2_lines > div {
    width: 50px;
    margin-top: 7px;
    border-bottom: 3px solid var(--green_transparent);
}

@media  (max-width: 720px) {
    .title_h2_lines {
        display: none;
    }
}

.block {
    display: flex;
}

.flex {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
}

.cat_description {
    margin: auto;
}

.latest_litters {
    flex-direction: column;
}

.latest_litters .litter_preview {
    margin-right: 0;
    margin-left: 0;
}

.cat_slider {
    width: 60%;
    height: 300px;
    border: 3px solid var(--green_transparent);
}

@media (max-width: 720px) {
    .block {
        flex-direction: column;
    }
}

.litter_preview_parents_cat_name {
    font-weight: bold;
}

.litter_preview_parents {
    display: flex;
}

@media (max-width: 1000px) {
    .litter_preview_parents_cat_name {
        font-weight: normal;
    }
}
.litter_preview {
    --margin-between-media: 10px;
    margin: var(--margin-between-media);
    padding: 10px;
    border: 1px solid #319b21;
}

.litter_preview_media {
    margin-left: calc(-1 * var(--margin-between-media));
    margin-right: calc(-1 * var(--margin-between-media));
    display: flex;
    justify-content: space-around;
}

.litter_preview_title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
}

.litter_preview .cat_avatar {
    margin: 5px;
}

@media (min-width: 1000px) {
    .big_letter {
        font-size: xx-large;
    }
}

@media (max-width: 1000px) {
    .litter_preview_title .link {
        margin: auto;
    }

    .big_letter {
        font-weight: bold;
    }

    .litter_preview_media {
        flex-direction: column;
    }

    .litter_preview .cat_avatar {
        margin: 5px auto;
        height: auto;
    }

    .litter_preview_title {
        flex-direction: column;
    }

    .litter_preview_title > span:nth-child(1) {
        font-size: large;
        display: flex;
        justify-content: space-between;
    }
}
.cat_avatar > img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    display: block;
}

.cat_avatar {
    width: 200px;
    height: 200px;
    vertical-align: middle;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    text-align: center;
}

a>.cat_avatar{
    cursor: pointer;
}


.cat_info_container {
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.media {
    display: flex;
    flex-wrap: wrap;
}

.media > * {
    flex: 1 1;
    width: 400px;
    margin: 10px;
}

.cat_info_container > div > img {
    width: 300px;
    height: auto;
}

@media (max-width: 1000px) {
    .cat_info_container {
        flex-direction: column-reverse;
        margin-top: 0;
    }

    .cat_info_container .cat_avatar {
        width: auto;
        height: auto;
    }

    .media > * {
        width: 100%;
    }
}

.responsiveTable td.pivoted:last-child {
    padding-left: 0 !important;
    margin: 10px;
}
.search_row {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
}

.search_row > * {
    margin: 5px;
}
.cat_preview {
    margin: 10px;
    padding: 10px;
    max-width: 250px;
}

.cat_preview_info {
    margin: 10px auto;
}

.cat_preview_name {
    font-weight: bold;
}

.cats_list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.cats_list .cat_preview {
    box-shadow: inset 0 0 7px 0 green;
    width: 100%;
}

@media (max-width: 1000px) {
    .cats_list .cat_preview .cat_avatar {
        height: auto;
    }
}


.contacts a{
    text-decoration: none;
    color: black;
}

.contacts{
    display: flex;
    flex-wrap: wrap;
}

.avatar{
    margin: 10px;
    padding: 10px;
    border: 3px solid  var(--green_transparent);
    width: 250px;
    min-height: 250px;
}

.avatar img{
    width: inherit;
    height: auto;
}

.auth_form{
    text-align: center;
}

.auth_form input{
    /*margin: auto;*/
}

.error_message{
    color: var(--red);
    margin-bottom: 20px;
    margin-top: -10px;
}

.cat_toolbar{
    display: flex;
    justify-content: space-evenly;
}

.cat_toolbar > *{
    cursor: pointer;
}

.media_control {
    margin-bottom: 30px;
}

.cat_media {
    width: 400px;
    margin-bottom: 30px;
    display: flex;
}

.cat_media > img {
    width: 100%;
    height: auto;
}

.cat_media .toolbar {
    text-align: center;
    padding: 5px;
}

.cat_media .toolbar > * {
    margin-bottom: 10px;
    cursor: pointer;
}
.cat_table_cell{
    padding: 10px;
}
th{
    /*text-align: left;*/
}

.mb_20{
    margin-bottom: 20px;
}

.form-input{
    padding: 10px 15px;
    border-radius: 3px;
    width: calc(100% - 30px);
    border: 1px solid hsl(0,0%,80%);
    margin-bottom: 20px;
}

.form-select{
    margin-bottom: 20px;
}

.form {
    width: 400px;
    margin: 20px auto;
}

.litter_parent {
    display: flex;
}

.search_row {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
}

@media (max-width: 720px) {
    .search_row {
        flex-direction: column;
    }

    .litter_parent {
        flex-direction: column;
    }

    .litter_parent .cat_avatar {
        height: auto;
    }
}

.search_row > * {
    padding: 10px;
}
.AboutCatsText_wrapper__1GiHx{
    max-width: 100%;
  
}
.AboutCatsText_mainText__3D4qN{
    text-align: justify;
    font-size: 17px;
}
.AboutCatsText_title__1FGfp {
    font-size: 27px;
    color: green;
}
.AboutCatsText_rep__3utg8{
    text-align: right;
    font-style: italic;
    font-size: 17px;
}
.litter_toolbar{
    display: flex;
    justify-content: space-evenly;
}

.litter_toolbar > *{
    cursor: pointer;
}

body {
    margin: 0 !important;
}


.react-responsive-modal-overlay .react-responsive-modal-modal {
    background: var(--green);
    border-radius: 15px;
    padding: 56px 56px 60px 56px;
}

.react-responsive-modal-overlay {
    background: rgba(77, 77, 77, 0.53);
    padding: 0;
}

.react-responsive-modal-closeButton {
    border-radius: 50%;
    margin: 7px 7px 0 0;
    border: 1px solid #B9B9B9;
    padding: 4px;
    cursor: pointer;
}

