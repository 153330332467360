.block {
    display: flex;
}

.flex {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
}

.cat_description {
    margin: auto;
}

.latest_litters {
    flex-direction: column;
}

.latest_litters .litter_preview {
    margin-right: 0;
    margin-left: 0;
}

.cat_slider {
    width: 60%;
    height: 300px;
    border: 3px solid var(--green_transparent);
}

@media (max-width: 720px) {
    .block {
        flex-direction: column;
    }
}
